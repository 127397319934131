var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "模板设计器",
        top: "4vh",
        "append-to-body": true,
        "destroy-on-close": true,
        visible: _vm.dialogFormVisible,
        width: "92%",
        fullscreen: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        open: _vm.open,
        opened: _vm.opened,
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.postData, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { staticStyle: { margin: "5px 0" } },
            [
              _c("label", [_vm._v("模板名称：")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入模板名称" },
                model: {
                  value: _vm.postData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.postData, "name", $$v)
                  },
                  expression: "postData.name",
                },
              }),
            ],
            1
          ),
          _c("el-row", { staticStyle: { margin: "5px 0" } }, [
            _c("label", [_vm._v("模板内容：")]),
          ]),
          _c("el-row", [
            _c(
              "div",
              { staticClass: "design" },
              [
                _vm.is_error
                  ? _c(
                      "div",
                      {
                        staticClass: "el-alert el-alert--error is-light",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("i", {
                          staticClass: "el-alert__icon el-icon-error is-big",
                        }),
                        _c("div", { staticClass: "el-alert__content" }, [
                          _c(
                            "span",
                            { staticClass: "el-alert__title is-bold" },
                            [
                              _vm._v(
                                " 创建插件失败，当前浏览器不支持插件，或插件在当前电脑上没有安装！ "
                              ),
                            ]
                          ),
                          _c("p", { staticClass: "el-alert__description" }, [
                            _vm._v(" 特别提示： "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.design.gr_plugin_setup_url,
                                  target: "_blank",
                                },
                              },
                              [_vm._v(" 点击下载WEB报表插件安装程序 ")]
                            ),
                            _vm._v(
                              " ，下载后双击下载的文件进行安装，安装完成后重新打开当前网页。 "
                            ),
                          ]),
                          _c("p", { staticClass: "el-alert__description" }, [
                            _vm._v(" 请参考: "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.design.gr_plugin_required_url,
                                  target: "_blank",
                                },
                              },
                              [_vm._v(" 浏览器插件兼容性说明 ")]
                            ),
                            _vm._v(" ，选用支持插件的浏览器查看当前网页。 "),
                          ]),
                          _c("i", {
                            staticClass: "el-alert__closebtn el-icon-close",
                          }),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.design.typeid == "classid"
                  ? [
                      _c(
                        "object",
                        {
                          attrs: {
                            id: "ReportDesignerclassid",
                            classid: _vm.design.typeid_value,
                            width: "100%",
                            height: "100%",
                          },
                        },
                        [
                          _c("param", {
                            attrs: {
                              name: "SerialNo",
                              value: _vm.design.gr_SerialNo,
                            },
                          }),
                          _c("param", {
                            attrs: {
                              name: "UserName",
                              value: _vm.design.gr_UserName,
                            },
                          }),
                        ]
                      ),
                    ]
                  : [
                      _c(
                        "object",
                        {
                          attrs: {
                            id: "ReportDesignertype",
                            type: _vm.design.typeid_value,
                            width: "100%",
                            height: "100%",
                          },
                        },
                        [
                          _c("param", {
                            attrs: {
                              name: "SerialNo",
                              value: _vm.design.gr_SerialNo,
                            },
                          }),
                          _c("param", {
                            attrs: {
                              name: "UserName",
                              value: _vm.design.gr_UserName,
                            },
                          }),
                        ]
                      ),
                    ],
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogFormVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.OnSaveReport } },
            [_vm._v("保 存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }