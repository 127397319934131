<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="模板设计器"
    top="4vh"
    :append-to-body="true"
    :destroy-on-close="true"
    :visible.sync="dialogFormVisible"
    width="92%"
    fullscreen
    @open="open"
    @opened="opened"
    @close="close"
  >
    <el-form ref="form" :model="postData" label-position="top">
      <el-row style="margin: 5px 0">
        <label>模板名称：</label>
        <el-input
          v-model="postData.name"
          style="width: 200px"
          placeholder="请输入模板名称"
        ></el-input>
      </el-row>
      <el-row style="margin: 5px 0">
        <label>模板内容：</label>
      </el-row>
      <el-row>
        <div class="design">
          <div
            v-if="is_error"
            role="alert"
            class="el-alert el-alert--error is-light"
          >
            <i class="el-alert__icon el-icon-error is-big"></i>
            <div class="el-alert__content">
              <span class="el-alert__title is-bold">
                创建插件失败，当前浏览器不支持插件，或插件在当前电脑上没有安装！
              </span>
              <p class="el-alert__description">
                特别提示：
                <a :href="design.gr_plugin_setup_url" target="_blank">
                  点击下载WEB报表插件安装程序
                </a>
                ，下载后双击下载的文件进行安装，安装完成后重新打开当前网页。
              </p>
              <p class="el-alert__description">
                请参考:
                <a :href="design.gr_plugin_required_url" target="_blank">
                  浏览器插件兼容性说明
                </a>
                ，选用支持插件的浏览器查看当前网页。
              </p>
              <i class="el-alert__closebtn el-icon-close"></i>
            </div>
          </div>
          <template v-if="design.typeid == 'classid'">
            <object
              id="ReportDesignerclassid"
              :classid="design.typeid_value"
              width="100%"
              height="100%"
            >
              <param name="SerialNo" :value="design.gr_SerialNo" />
              <param name="UserName" :value="design.gr_UserName" />
            </object>
          </template>
          <template v-else>
            <object
              id="ReportDesignertype"
              :type="design.typeid_value"
              width="100%"
              height="100%"
            >
              <param name="SerialNo" :value="design.gr_SerialNo" />
              <param name="UserName" :value="design.gr_UserName" />
            </object>
          </template>
        </div>
      </el-row>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="OnSaveReport">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
  // 修改 /ruilangDesign?data_id=1182
  // 新建 /ruilangDesign?tpl_id=xx&depart_id=xx
  import { postAction } from '@/api/Employee'

  var gr_InstallPath = 'grinstall',
    gr_Version = '6,7,20,1101'
  //报表插件目前只能在32位浏览器中使用
  var _gr_platform = window.navigator.platform,
    _gr_isX64 = _gr_platform.indexOf('64') > 0,
    _gr_agent = navigator.userAgent.toLowerCase(),
    _gr_isIE = _gr_agent.indexOf('msie') > 0,
    gr_CodeBase = _gr_isIE
      ? 'codebase="' +
        gr_InstallPath +
        (_gr_isX64 ? '/grbsctl6x64.cab' : '/grbsctl6.cab') +
        '#Version=' +
        gr_Version +
        '"'
      : '' //区分浏览器(IE or not)

  export default {
    name: 'RuilangDesignDialog',
    props: {
      add: {
        type: Object,
        default: () => {
          return {
            tpl_id: 0,
            depart_id: 0,
          }
        },
      },
      edit: {
        type: Object,
        default: () => {
          return {
            data_id: 0,
          }
        },
      },
    },
    data: function () {
      return {
        dialogFormVisible: false,
        design: {
          tpl_id: 1, // 模板ID(新增用的)
          typeid: '',
          typeid_value: '',
          type: 'designer', //"printviewer",
          OnSaveReport: 'OnSaveReport',
          report: '',
          data: {},
          SaveReportUrl: '/setAdmin/print/tpl-modify',
          TplIdPublicViewURl: '/setAdmin/print/tplid-public-view',
          TplViewUrl: '/setAdmin/print/tpl-view',
          gr_UserName: '锐浪报表插件本机开发测试注册',
          gr_SerialNo:
            '8PJH495VA61FLI5TG0L4KB2337F1G7AKLD6LNNA9F9T28IKRU6N33P8Z6XX4BUYB5E9NZ6INMD5T8EN47IX63VV7F9BJHB5ZJQQ6MX3J3V12C4XDHU97SXX6X3VA57KCB6',
          gr_plugin_setup_url: 'http://www.rubylong.cn/download/grbsctl6.exe',
          gr_plugin_required_url:
            'http://www.rubylong.cn/gridreport/doc/plugins_browser.htm',
        },
        is_error: false,
        designer: '',
        postData: { id: 0 }, // 修改
      }
    },
    computed: {},
    watch: {},
    created() {
      if (_gr_isIE) {
        let key = ''
        if (isdesigner) {
          key = 'CE666189-5D7C-42ee-AAA4-E5CB375ED3C7'
        } else {
          if (this.design.type == 'printviewer') {
            key = 'ABB64AAC-D7E8-4733-B052-1B141C92F3CE'
          } else {
            key = '600CD6D9-EBE1-42cb-B8DF-DFB81977122E'
          }
        }
        this.design.typeid = 'classid'
        this.design.typeid_value = 'clsid:' + key + gr_CodeBase
      } else {
        this.design.typeid = 'type'
        this.design.typeid_value = 'application/x-grplugin6-' + this.design.type
      }
    },
    mounted() {},
    methods: {
      close() {
        this.postData = { id: 0 }
        this.design.tpl_id = 0
        this.is_error = false
      },
      open() {
        if (this.edit.hasOwnProperty('data_id') && this.edit.data_id > 0) {
          this.postData.id = this.edit.data_id
        }
        if (this.add.hasOwnProperty('tpl_id') && this.add.tpl_id > 0) {
          this.design.tpl_id = this.add.tpl_id
        }
        if (this.add.hasOwnProperty('depart_id') && this.add.depart_id > 0) {
          this.postData.depart_id = this.add.depart_id
        }
        this.init()
        console.log(
          'init',
          this.add,
          this.edit,
          this.postData.id,
          this.design.tpl_id
        )
      },
      opened() {},
      init() {
        if (this.postData.id > 0) {
          // 修改
          postAction(this.design.TplViewUrl, { id: this.postData.id })
            .then((res) => {
              console.log(res, '获取成功')
              this.design.report = res.data.tpl_data
              this.design.data = res.data.demo_data
              this.postData = {
                id: this.postData.id,
                name: res.data.name,
              }
              // 加载报表插件
              this.reportInit()
            })
            .catch((err) => {
              console.log(err, '')
              this.$message({
                message: err.msg,
                type: 'warning',
              })
              return false
            })
        } else if (this.design.tpl_id > 0) {
          // 新增
          postAction(this.design.TplIdPublicViewURl, {
            tpl_id: this.design.tpl_id,
          })
            .then((res) => {
              console.log(res, '获取成功')
              this.design.report = res.data.public_data
              this.design.data = res.data.demo_data
              this.postData.bill_type = res.data.bill_type
              this.postData.type = res.data.type
              this.postData.demo_data = res.data.demo_data
              this.postData.id = 0
              // 加载报表插件
              this.reportInit()
            })
            .catch((err) => {
              console.log(err, '')
              this.$message({
                message: err.msg,
                type: 'warning',
              })
              return false
            })
        } else {
          this.$message({
            message: '参数错误',
            type: 'warning',
          })
        }
      },
      /**
       * 在报表执行每个任务动作前触发本事件，任务动作对应DoAction方法所能执行的任务
       * @GRDesignerAction Action
       */
      BeforeDoAction(Action) {
        console.log('动作，可控制权限', Action)
        if (Action == 409) {
          // this.designer.DefaultAction = false; //屏蔽控件本身的行为
        }
      },

      /**
       * 初始化报表插件
       */
      reportInit() {
        let ds = document.getElementById('ReportDesigner' + this.design.typeid)
        console.log('ReportDesigner' + this.design.typeid, ds)
        if (!ds.Report) {
          this.is_error = true
          alert(
            '创建插件失败，当前浏览器不支持插件，或插件在当前电脑上没有安装！'
          )
          return false
        }
        let that = this
        this.designer = ds
        this.designer.Report.LoadFromStr(this.design.report)
        this.designer.Reload()

        // this.designer.SetOptionValue(302,false) // 指定是否显示工具栏
        // GRDesignerOption 枚举
        // this.designer.UpdateUI()

        if (this.design.OnSaveReport) {
          this.designer.OnSaveReport = function () {
            that.OnSaveReport()
          }
        }
        this.designer.OnBeforeDoAction = function (Action) {
          that.BeforeDoAction(Action)
        }
      },

      /**
       * 保存模板
       * @constructor
       */
      OnSaveReport() {
        //注释掉下面第一行代码，并取消第二行代码的注释，即可将设计结果保存到服务器对应目录下的文件中
        // alert('为了保护报表的正常演示，这里放弃了对报表设计的保存！')
        this.designer.Post()
        this.postData.tpl_data = JSON.stringify(
          JSON.parse(this.designer.Report.SaveToStr())
        )
        postAction(this.design.SaveReportUrl, this.postData)
          .then((res) => {
            console.log(res, '模板更新成功')
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.dialogFormVisible = false
            this.$emit('design-save-callback')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style scoped>
  .design {
    width: 100%;
    height: calc(90vh - 60px - 55px - 20px * 2 - 55px);
    /*border: #0aaafa solid 1px;*/
  }
</style>
