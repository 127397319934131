<!--
 * @auther: luwc
 * @Description: 打印模板
 * @FilePath: \sd-vue-admin\src\views\setting\printSet\index.vue
-->
<template>
  <div>
    <el-tabs type="border-card" @tab-click="handleTabChage">
      <el-tab-pane label="打印参数设置">
        <!--      打印参数设置-->
        <el-table
          v-loading="loading"
          stripe
          :data="tableTab0Data.data"
          border
          style="width: 100%"
        >
          <el-table-column
            fixed
            prop="depart_name"
            label="部门名称"
            width="200"
          ></el-table-column>
          <el-table-column prop="info" label="打印参数">
            <template slot-scope="scope">
              <el-form label-position="right" label-width="80px" :model="scope">
                <el-form-item label="公司名称">
                  <el-input v-model="scope.row.name"></el-input>
                </el-form-item>
                <el-form-item label="服务热线">
                  <el-input v-model="scope.row.hotline"></el-input>
                </el-form-item>
                <el-form-item label="联系人员">
                  <el-input v-model="scope.row.link_name"></el-input>
                </el-form-item>
                <el-form-item label="打印备注">
                  <el-input v-model="scope.row.info" type="textarea"></el-input>
                </el-form-item>
                <el-form-item label="公司公章">
                  <el-input
                    v-model="scope.row.sael_url"
                    style="width: 400px"
                  ></el-input>
                  <el-upload
                    style="display: inline-block; margin-left: 10px"
                    class="upload-demo"
                    :show-file-list="false"
                    :headers="headers"
                    :action="baseURL + '/baseAdmin/common/upload-image'"
                    :on-success="handleUploadImage"
                    :data="{ ext: scope.row.depart_id }"
                    :limit="1"
                    name="files"
                    accept="image/png, image/jpeg, image/gif"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <span slot="tip" class="el-upload__tip">
                      只能上传jpg/png/gif文件，且不超过500kb
                    </span>
                  </el-upload>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="province" label="独立模板" width="200">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.status == 2 ? '点击开启' : '点击禁用'"
                :enterable="false"
                placement="top"
              >
                <el-switch
                  v-model="scope.row.status"
                  active-value="1"
                  inactive-value="2"
                  @change="handlerSwitch(scope.row.depart_id, scope.row.status)"
                ></el-switch>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleSave(scope.row)"
              >
                保存
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="queryForm.pageNo"
          layout="total, sizes, prev, pager, next, jumper"
          :page-size="queryForm.pageSize"
          :total="tableTab0Data.total"
          background
          @current-change="handleCurrentChange0"
          @size-change="handleSizeChange0"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="单据打印模板">
        <el-row :gutter="22">
          <el-col :span="4">
            <el-table
              v-loading="loading"
              stripe
              :data="tableDepart"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="depart_name"
                label="选择部门"
                align="center"
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    @click="handleChangeDeartIdTpl(scope.row)"
                  >
                    <span
                      v-if="nowTab1DepartId == scope.row.depart_id"
                      style="color: red"
                    >
                      {{ scope.row.depart_name }}
                    </span>
                    <span v-else>
                      {{ scope.row.depart_name }}
                    </span>
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="18">
            <div style="margin-bottom: 10px; display: flex">
              <client-search
                ref="clientSearch"
                :popper-class="'selectDC'"
                @select-id="selectClient"
              ></client-search>
              <el-button
                style="margin-left: 10px"
                type="primary"
                @click="handleQuery"
              >
                查询
              </el-button>
            </div>
            <el-table
              v-loading="loading"
              stripe
              :data="tableDepartTpl"
              :span-method="objectTplSpanMethod"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="bill_type_text"
                label="类型"
                width="180"
              ></el-table-column>
              <el-table-column prop="name" label="模板名称" width="220">
                <template slot-scope="scope">
                  <template v-if="scope.row.id == 0">
                    <el-button
                      size="mini"
                      round
                      type="primary"
                      icon="el-icon-plus"
                      @click="handleNewTpl(scope.row)"
                    >
                      新增
                    </el-button>
                  </template>
                  <template v-else>
                    <el-link @click="handleTplEdit(scope.row)">
                      <template v-if="scope.row.is_default == 1">
                        <el-badge value="默认" class="item" type="info">
                          {{ scope.row.name }}
                        </el-badge>
                      </template>
                      <template v-else>
                        {{ scope.row.name }}
                      </template>
                    </el-link>
                  </template>
                </template>
              </el-table-column>
              <el-table-column prop="cust_count" label="关联客户">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.is_default == 2 && scope.row.id > 0"
                    type="text"
                    size="small"
                    @click="handleLinkClieck(scope.row)"
                  >
                    {{ scope.row.cust_count }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="220">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.id > 0"
                    type="text"
                    size="small"
                    @click="handleTplEdit(scope.row)"
                  >
                    编辑
                  </el-button>
                  <el-button
                    v-if="scope.row.id > 0"
                    type="text"
                    size="small"
                    @click="handleTplCopy(scope.row)"
                  >
                    复制
                  </el-button>
                  <el-button
                    v-if="scope.row.is_default == 2 && scope.row.id > 0"
                    type="text"
                    size="small"
                    @click="handleTplDel(scope.row)"
                  >
                    删除
                  </el-button>
                  <el-button
                    v-if="scope.row.is_default == 2 && scope.row.id > 0"
                    type="text"
                    size="small"
                    @click="handleTplIsDefault(scope.row)"
                  >
                    设置默认
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <batch-link-client
          ref="BatchLinkClient"
          dayin
          :is-set="true"
          @selectGoods="changeClient"
          @refresh="fetchTab1Data"
        ></batch-link-client>
      </el-tab-pane>
      <!--      <el-tab-pane label="报表打印模板">报表打印模板</el-tab-pane>-->
    </el-tabs>
    <ruilang-design-dialog
      ref="RuilangDesignDialog"
      :edit="ruilangEdit"
      :add="ruilangAdd"
      @design-save-callback="designSaveCallback"
    ></ruilang-design-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import RuilangDesignDialog from '@/baseComponents/ruiLangDesign/ruilangDesignDialog'
  import BatchLinkClient from '@/views/project/sale/control/setPrice/components/linkClient/index.vue'
  import { baseURL } from '@/config'
  import ClientSearch from '@/baseComponents/clientSearch'

  export default {
    components: { BatchLinkClient, RuilangDesignDialog, ClientSearch },
    data() {
      return {
        queryForm: {
          pageNo: 1,
          pageSize: 8,
        },
        cust_id: '',
        setLinkClientId: 0,
        headers: { token: '' },
        loading: true,
        active: '0',
        tableTab0Data: [],
        tableDepart: [],
        tableDepartTpl: [],
        nowTab1DepartId: 0,
        ruilangEdit: {},
        ruilangAdd: {},
      }
    },
    computed: {
      baseURL() {
        return baseURL
      },
    },
    watch: {},
    created() {
      this.headers.token = this.$store.getters['user/accessToken']
      this.fetchTab0Data()
    },
    methods: {
      handleQuery() {
        this.handleChangeDeartIdTpl({ depart_id: this.nowTab1DepartId })
      },
      handleSizeChange0(val) {
        this.queryForm.pageSize = val
        this.fetchTab0Data()
      },
      handleCurrentChange0(val) {
        this.queryForm.pageNo = val
        this.fetchTab0Data()
      },
      /**
       * 设计器保存成功回调
       */
      designSaveCallback() {
        this.handleChangeDeartIdTpl({ depart_id: this.nowTab1DepartId })
      },
      fetchTab1Data() {
        postAction('/setAdmin/print/tpl-depart', {})
          .then((res) => {
            this.loading = false
            console.log(res, '成功')
            this.tableDepart = res.data
            if (res.data.length > 0) {
              this.nowTab1DepartId = res.data[0].depart_id
              this.handleChangeDeartIdTpl(res.data[0])
            }
          })
          .catch((err) => {
            this.loading = false
            console.log(err, '')
          })
      },
      fetchTab0Data() {
        postAction('/setAdmin/print/list', this.queryForm)
          .then((res) => {
            this.loading = false
            console.log(res, '成功')
            this.tableTab0Data = res
          })
          .catch((err) => {
            this.loading = false
            console.log(err, '')
          })
      },
      /**
       * 根据部门获取模板列表
       */
      handleChangeDeartIdTpl(row) {
        this.nowTab1DepartId = row.depart_id
        this.loading = true
        postAction('/setAdmin/print/tpl-list', {
          type: 1,
          depart_id: row.depart_id,
          cust_id: this.cust_id,
        })
          .then((res) => {
            this.loading = false
            console.log(res, '成功')
            this.tableDepartTpl = res.data
          })
          .catch((err) => {
            this.loading = false
            console.log(err, '')
          })
      },
      /**
       * 新增模板
       */
      handleNewTpl(row) {
        this.ruilangEdit = {}
        this.ruilangAdd = {
          tpl_id: row.tpl_id,
          depart_id: this.nowTab1DepartId,
        }
        this.$refs.RuilangDesignDialog.dialogFormVisible = true
      },
      /**
       * 提交选中的客户ID
       */
      changeClient(rows) {
        console.log(rows, '已选中的')
        let cust_ids = JSON.parse(rows.cust_id)

        let goods = JSON.parse(rows.customer)
        if (goods.length > 0) {
          goods.forEach(function (item) {
            cust_ids.push(item.cust_id)
          })
          cust_ids = cust_ids.length == 0 ? '' : cust_ids.join(',')
          postAction('/setAdmin/print/tpl-modify', {
            cust_ids,
            id: this.setLinkClientId,
          })
            .then((res) => {
              console.log(res, '更新')
              if (res.code == 200) {
                this.$message.success(res.msg)
                this.handleChangeDeartIdTpl({ depart_id: this.nowTab1DepartId })
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {
              console.log(err, '')
              this.$message.error(err.msg)
            })
        }
      },
      /**
       * 关联客户
       */
      handleLinkClieck(row) {
        this.setLinkClientId = row.id
        console.log('row.cust_ids', row.cust_ids)
        if (row.cust_ids !== '') {
          this.$refs['BatchLinkClient'].cust_id = JSON.stringify(
            row.cust_ids.split(',')
          )
        } else {
          this.$refs['BatchLinkClient'].cust_id = row.cust_ids
        }
        this.$refs['BatchLinkClient'].id = row.id
        this.$refs['BatchLinkClient'].showDialog = true
      },
      /**
       * 编辑模板
       */
      handleTplEdit(row) {
        this.ruilangAdd = {}
        this.ruilangEdit = {
          data_id: row.id,
        }
        this.$refs.RuilangDesignDialog.dialogFormVisible = true
      },
      /**
       * 复制
       * @param row
       */
      handleTplCopy(row) {
        postAction('/setAdmin/print/tpl-copy', { id: row.id })
          .then((res) => {
            console.log(res, '复制')
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.handleChangeDeartIdTpl({ depart_id: this.nowTab1DepartId })
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {
            console.log(err, '')
            this.$message.error(err.msg)
          })
      },
      /**
       * 删除模板
       * @param row
       */
      handleTplDel(row) {
        postAction('/setAdmin/print/tpl-delete', { id: row.id })
          .then((res) => {
            console.log(res, '删除')
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.handleChangeDeartIdTpl({ depart_id: this.nowTab1DepartId })
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {
            console.log(err, '')
            this.$message.error(err.msg)
          })
      },
      /**
       * 设置为默认模板
       * @param row
       */
      handleTplIsDefault(row) {
        postAction('/setAdmin/print/tpl-modify', { is_default: 1, id: row.id })
          .then((res) => {
            console.log(res, '更新')
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.handleChangeDeartIdTpl({ depart_id: this.nowTab1DepartId })
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {
            console.log(err, '')
            this.$message.error(err.msg)
          })
      },
      /**
       * 合并单元格
       */
      flitterData(arr) {
        let spanOneArr = [],
          concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.bill_type === arr[index - 1].bill_type) {
              //第一列需合并相同内容的判断条件
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return {
          one: spanOneArr,
        }
      },
      /**
       * 合并列
       */
      objectTplSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          const _row = this.flitterData(this.tableDepartTpl).one[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      /**
       * 保存打印参数设置
       * @param row
       */
      handleSave(row) {
        console.log(row)
        postAction('/setAdmin/print/modify', row)
          .then((res) => {
            console.log(res, '成功')
            if (res.code == 200) {
              this.$message.success('更新成功')
              this.fetchTab0Data()
            } else if (res.code == 1) {
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {
            console.log(err, '')
            this.$message.error(err.msg)
          })
      },
      /**
       * TAB切换事件
       * @param e
       */
      handleTabChage(e) {
        console.log(e, 'tab')
        this.active = e.index
        if (e.index == '0') {
          this.fetchTab0Data()
        } else if (e.index == '1') {
          this.fetchTab1Data()
        } else {
        }
        this.loading = true
      },
      selectClient(val) {
        this.cust_id = val
      },
      handlerSwitch(depart_id, status) {
        console.log(depart_id, status, 'swt')
        postAction('/setAdmin/print/modify', { depart_id, status })
          .then((res) => {
            console.log(res, '成功')
            if (res.code == 200) {
              this.$message.success('更新成功')
              this.fetchTab0Data()
            } else if (res.code == 1) {
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {
            console.log(err, '')
            this.$message.error(err.msg)
          })
      },
      handleUploadImage(response, file, fileList) {
        console.log(this.tableTab0Data)
        this.tableTab0Data.data.forEach(function (v) {
          if (v.depart_id == response.data[0].ext) {
            v.sael_url = response.data[0].url
          }
        })
      },
    },
  }
</script>

<style>
  .el-badge__content.is-fixed {
    transform: translateY(0%) translateX(150%);
  }
</style>
