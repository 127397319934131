var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleTabChage },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "打印参数设置" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    stripe: "",
                    data: _vm.tableTab0Data.data,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "depart_name",
                      label: "部门名称",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "info", label: "打印参数" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form",
                              {
                                attrs: {
                                  "label-position": "right",
                                  "label-width": "80px",
                                  model: scope,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "公司名称" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: scope.row.name,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "name", $$v)
                                        },
                                        expression: "scope.row.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "服务热线" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: scope.row.hotline,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "hotline", $$v)
                                        },
                                        expression: "scope.row.hotline",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "联系人员" } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: scope.row.link_name,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "link_name", $$v)
                                        },
                                        expression: "scope.row.link_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "打印备注" } },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "textarea" },
                                      model: {
                                        value: scope.row.info,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "info", $$v)
                                        },
                                        expression: "scope.row.info",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "公司公章" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "400px" },
                                      model: {
                                        value: scope.row.sael_url,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "sael_url", $$v)
                                        },
                                        expression: "scope.row.sael_url",
                                      },
                                    }),
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "upload-demo",
                                        staticStyle: {
                                          display: "inline-block",
                                          "margin-left": "10px",
                                        },
                                        attrs: {
                                          "show-file-list": false,
                                          headers: _vm.headers,
                                          action:
                                            _vm.baseURL +
                                            "/baseAdmin/common/upload-image",
                                          "on-success": _vm.handleUploadImage,
                                          data: { ext: scope.row.depart_id },
                                          limit: 1,
                                          name: "files",
                                          accept:
                                            "image/png, image/jpeg, image/gif",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary",
                                            },
                                          },
                                          [_vm._v("点击上传")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "el-upload__tip",
                                            attrs: { slot: "tip" },
                                            slot: "tip",
                                          },
                                          [
                                            _vm._v(
                                              " 只能上传jpg/png/gif文件，且不超过500kb "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "province",
                      label: "独立模板",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    scope.row.status == 2
                                      ? "点击开启"
                                      : "点击禁用",
                                  enterable: false,
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": "1",
                                    "inactive-value": "2",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handlerSwitch(
                                        scope.row.depart_id,
                                        scope.row.status
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.status,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "status", $$v)
                                    },
                                    expression: "scope.row.status",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSave(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 保存 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryForm.pageNo,
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-size": _vm.queryForm.pageSize,
                  total: _vm.tableTab0Data.total,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange0,
                  "size-change": _vm.handleSizeChange0,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "单据打印模板" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 22 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            stripe: "",
                            data: _vm.tableDepart,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "depart_name",
                              label: "选择部门",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleChangeDeartIdTpl(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.nowTab1DepartId ==
                                        scope.row.depart_id
                                          ? _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.depart_name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.depart_name
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            display: "flex",
                          },
                        },
                        [
                          _c("client-search", {
                            ref: "clientSearch",
                            attrs: { "popper-class": "selectDC" },
                            on: { "select-id": _vm.selectClient },
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v(" 查询 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            stripe: "",
                            data: _vm.tableDepartTpl,
                            "span-method": _vm.objectTplSpanMethod,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "bill_type_text",
                              label: "类型",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "模板名称",
                              width: "220",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.id == 0
                                      ? [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                round: "",
                                                type: "primary",
                                                icon: "el-icon-plus",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleNewTpl(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 新增 ")]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "el-link",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleTplEdit(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              scope.row.is_default == 1
                                                ? [
                                                    _c(
                                                      "el-badge",
                                                      {
                                                        staticClass: "item",
                                                        attrs: {
                                                          value: "默认",
                                                          type: "info",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              scope.row.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(scope.row.name) +
                                                        " "
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ],
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "cust_count", label: "关联客户" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.is_default == 2 &&
                                    scope.row.id > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleLinkClieck(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row.cust_count) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              fixed: "right",
                              label: "操作",
                              width: "220",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.id > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleTplEdit(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 编辑 ")]
                                        )
                                      : _vm._e(),
                                    scope.row.id > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleTplCopy(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 复制 ")]
                                        )
                                      : _vm._e(),
                                    scope.row.is_default == 2 &&
                                    scope.row.id > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleTplDel(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        )
                                      : _vm._e(),
                                    scope.row.is_default == 2 &&
                                    scope.row.id > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleTplIsDefault(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 设置默认 ")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("batch-link-client", {
                ref: "BatchLinkClient",
                attrs: { dayin: "", "is-set": true },
                on: {
                  selectGoods: _vm.changeClient,
                  refresh: _vm.fetchTab1Data,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ruilang-design-dialog", {
        ref: "RuilangDesignDialog",
        attrs: { edit: _vm.ruilangEdit, add: _vm.ruilangAdd },
        on: { "design-save-callback": _vm.designSaveCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }